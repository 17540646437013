<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Referensi Aplikasi
        </h5>
        <small class="text-muted">Referensi Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan kegiatan.</small>
      </b-col>
      <alert-module-detail :doc-data="appData" />
      <b-col
        v-if="!appData.nama_aplikasi"
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Referensi Arsitektur Domain Aplikasi sesuai dengan Renja'"
          label-for="vi-master_aplikasi"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Aplikasi atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_aplikasi"
              v-model="master_aplikasi"
              required
              name="master_aplikasi"
              :options="referenceData.aplikasi_list"
              placeholder="Pilih Nama Aplikasi atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Domain Aplikasi"
          label-for="vi-app_domain_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Domain Aplikasi"
            rules="required"
          >
            <v-select
              id="vi-app_domain_id"
              v-model="app_domain_id"
              required
              name="app_domain_id"
              :options="referenceData.ref_domain_aplikasi"
              placeholder="Domain Aplikasi"
              label="nama"
              @input="getAreaAplikasi()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Area Aplikasi"
          label-for="vi-app_area_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Area Aplikasi"
            rules="required"
          >
            <v-select
              id="vi-service_area_id"
              v-model="app_area_id"
              required
              name="app_area_id"
              :options="daftarAreaAplikasi"
              placeholder="Area Aplikasi"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama aplikasi yang digunakan atau dimiliki.'"
          label="Nama Aplikasi"
          label-for="vi-nama_aplikasi"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Aplikasi"
            rules="required"
          >
            <b-form-input
              id="vi-nama_aplikasi"
              v-model="appData.nama_aplikasi"
              required
              name="nama_aplikasi"
              placeholder="Nama Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Berisi keterangan fungsi dari aplikasi terhadap layanan yang didukung.'"
          label="Fungsi Aplikasi"
          label-for="vi-fungsi"
        >
          <validation-provider
            #default="{ errors }"
            name="Fungsi Aplikasi"
            rules="required"
          >
            <b-form-input
              id="vi-fungsi"
              v-model="appData.fungsi"
              required
              name="fungsi"
              placeholder="Fungsi Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-50"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Server yang digunakan oleh aplikasi, pilihan server yang digunakan didapat dari metadata perangkat keras server.'"
          label="Server Aplikasi"
          label-for="vi-server"
        >
          <validation-provider
            #default="{ errors }"
            name="Server Aplikasi"
            rules="required"
          >
            <b-form-input
              id="vi-server"
              v-model="appData.server"
              required
              name="server"
              placeholder="Server Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Berisi uraian atau deskripsi secara umum dari aplikasi.'"
          label="Uraian Aplikasi"
          label-for="vi-uraian_aplikasi"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Aplikasi"
            rules="required"
          >
            <b-form-textarea
              id="vi-uraian_aplikasi"
              v-model="appData.uraian_aplikasi"
              rows="8"
              required
              name="uraian_aplikasi"
              placeholder="Uraian Aplikasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormTextarea, BFormInput, BRow, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import AlertModuleDetail from '../detail/AlertModuleDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
    AlertModuleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      referenceData: {
        aplikasi_list: [],
        ref_domain_aplikasi: [],
        ref_area_aplikasi: [],
      },
      data_utama_id: '',
      aplikasi_id: '',
      master_aplikasi: '',
      daftarMasterAplikasi: { aplikasi_id: 'new', label: 'Tambah Referensi Aplikasi Baru' },
      daftarAreaAplikasi: [],
      appData: {},
      app_domain_id: '',
      app_area_id: '',
      nama_aplikasi: '',
      fungsi: '',
      server: '',
      uraian_aplikasi: '',
    }
  },
  watch: {
    actionStep() {
      this.saveAplikasi()
    },
  },
  mounted() {
    this.data_utama_id = Number(this.$route.params.id)
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/applications/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.coreData.data_utama_id,
          tahun_anggaran: this.coreData.tahun_anggaran,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data

            if (res.data.data.aplikasi_list) {
              const pics = [this.daftarMasterAplikasi]
              res.data.data.aplikasi_list.map(value => {
                const pic = value
                pic.label = `${value.nama_aplikasi} / ${value.domain_aplikasi} / ${value.area_aplikasi}`
                pics.push(pic)
                return true
              })
              this.referenceData.aplikasi_list = pics
            }
            this.setReference()
          }
        })
    },
    setSelected() {
      if (this.master_aplikasi.aplikasi_id !== 'new') {
        this.appData = this.master_aplikasi
        this.app_domain_id = {
          domain_aplikasi_id: this.appData.domain_aplikasi_id,
          nama: this.appData.domain_aplikasi,
        }
        this.getAreaAplikasi()

        this.app_area_id = {
          area_aplikasi_id: this.appData.area_aplikasi_id,
          nama: this.appData.area_aplikasi,
        }
      }
    },
    setReference() {
      this.$http.get('/clearance/applications', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.coreData.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            [this.appData] = res.data.data

            this.referenceData.ref_domain_aplikasi.map(contact => {
              if (contact.nama === this.appData.domain_aplikasi) {
                this.app_domain_id = contact
              }
              return true
            })
            this.getAreaAplikasi()
            this.referenceData.ref_area_aplikasi.map(contact => {
              if (contact.nama === this.appData.area_aplikasi) {
                this.app_area_id = contact
              }
              return true
            })
          }
        })
    },
    getAreaAplikasi() {
      this.daftarAreaAplikasi.splice(0)
      this.app_area_id = ''

      const filterVal = this.app_domain_id.domain_aplikasi_id
      const refData = this.referenceData.ref_area_aplikasi
      refData.map(contact => {
        if (contact.domain_aplikasi_id === filterVal) {
          this.daftarAreaAplikasi.push(contact)
        }
        return true
      })
    },
    saveAplikasi() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.coreData.data_utama_id,
        instansi_id: this.userData.instansi_id,
        domain_aplikasi_id: this.app_domain_id.domain_aplikasi_id,
        area_aplikasi_id: this.app_area_id.area_aplikasi_id,
        nama_aplikasi: this.appData.nama_aplikasi,
        fungsi: this.appData.fungsi,
        server: this.appData.server,
        uraian_aplikasi: this.appData.uraian_aplikasi,
        aplikasi_id: this.appData.aplikasi_id,
      }

      this.$http.post('/clearance/applications', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.coreData.data_utama_id,
        },
      })
        .then(res => {
          this.aplikasi_id = res.data.data.aplikasi_id
          const data = {
            status: true,
            msg: res.data.data,
          }
          data.msg.data_utama_id = this.clearanceId
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
